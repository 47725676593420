html,
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  height: 100%;
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}

.wrapper {
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
  width: 100%;
}
